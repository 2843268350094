import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { environment } from '../../environments/environment';
import { catchError, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { AppConfigService } from './app-config.service';
import { CheckCandidateApplications, CreateApplicationPayload, RegisterCandidatePayload } from '../models/candidate.model';
import { AUTH_TOKEN } from '../resources/auth-token';
import { CountryCallingCode } from '../models/country-calling-codes.model';
import { ToastrService } from 'ngx-toastr';
import { ErrorHandlingService } from './error-handling.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class CandidateRegisterService {

  private _callingCodes: CountryCallingCode[];

  constructor(
    private http: HttpClient,
    private configService: AppConfigService,
    protected toastr: ToastrService,
    private errorHandlingService: ErrorHandlingService,
    private router: Router
  ) { }

  createApplication(registerCandidatePayload: RegisterCandidatePayload): Observable<{ guid: string }> {
    const { inHouseQuestionSelectedAnswer, knockoutQuestionsSelectedAnswers, universalJobId, additionalJobsToApplyTo, phone, name, email, linkedinProfile } = registerCandidatePayload;

    const payload: CreateApplicationPayload = {
      //dateOfBirth always send as null, it's for security reasons, if someone sends a number, or without he will get false app guid
      dateOfBirth: null,
      candidatePhoneNumber: phone,
      candidateName: name,
      candidateEmail: email,
      linkedinProfile: linkedinProfile,
      inHouseQuestionSelectedAnswer: inHouseQuestionSelectedAnswer,
      knockoutQuestionsSelectedAnswers: knockoutQuestionsSelectedAnswers,
      additionalJobsToApply: []
    };

    if (universalJobId) {
      payload.universalJob = universalJobId;
    } else {
      payload.job = Number(sessionStorage.getItem('jobId'));
    }

    if (additionalJobsToApplyTo && additionalJobsToApplyTo.length) {
      payload.additionalJobsToApply = additionalJobsToApplyTo;
    }

    const referrer = this.configService.referrer;

    if (referrer) {
      if (referrer === 'direct traffic' && this.configService.config.organization.isAllJobs) {
        const url = window.location.href;
        const hostname = new URL(url).origin;
        payload.referer = hostname;
      } else {
        payload.referer = referrer;
      }
    }

    const universalJobsArray = this.configService.config.organization.universalJobs;

    const jobGuid = universalJobId ? universalJobsArray.length > 1
      ? this.getUniversalJobGuid(universalJobId) : universalJobsArray[0].guid : sessionStorage.getItem('jobGuid');

    const companyGuid = sessionStorage.getItem('companyGuid');

    return this.http.post<{ guid: string }>(`${environment.api}/${companyGuid}/${jobGuid}/candidate-and-application`, payload);
  }

  getUniversalJobGuid(universalJobId: number): string {
    const universalJob = this.configService.config.organization.universalJobs.find(unJob => unJob.id === universalJobId);
    return universalJob.guid;
  }

  submitReferer(type: string, referer: string, entityGuid?: string): Observable<any> {

    const payload: { type: string, referer: string, entityGuid?: string } = {
      type,
      referer,
      entityGuid
    };

    return this.http.post(
      environment.referrer,
      payload,
      {
        headers: new HttpHeaders({ Authorization: AUTH_TOKEN }),
      }
    ).pipe(
      catchError((errorResponse: HttpErrorResponse) => {
        return this.errorHandlingService.handleBackendError(errorResponse);
      })
    );
  }

  getRefererList(type: string, entityGuid?: string): Observable<any> {
    let params = new HttpParams();

    params = params.append('type', type);
    params = params.append('entityGuid', entityGuid);

    return this.http.get(
      environment.referrer,
      {
        headers: new HttpHeaders({ Authorization: AUTH_TOKEN }),
      }
    );
  }

  checkIfCandidateAlreadyApplied(candidateData: CheckCandidateApplications): Observable<{ guid: string }> {
    return this.http.post<{ guid: string }>(`${environment.api}/applications/guid`, candidateData);
  }

  getCountryCallingCodes(): Observable<CountryCallingCode[]> {
    const origin = window.location.origin;

    if (this._callingCodes) {
      return of(this._callingCodes);
    }

    return this.http.get<CountryCallingCode[]>(`${origin}/assets/country-calling-codes.json`)
      .pipe(
        tap((callingCodes: CountryCallingCode[]) => this._callingCodes = callingCodes)
      );
  }
}
